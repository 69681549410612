@import "styles/variables";
@import "styles/mixings";

.search {
  position: relative;
  width: 60%;
  margin: 0 auto 50px;

  @include media-breakpoint-down(xl) {
    width: 100%;
  }

  input {
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    opacity: 0.85;
    box-shadow: 0 15px 53px rgba(0, 0, 0, 0.23);
    border-radius: 9px;
    font-size: 19 * $px-base;
    line-height: 117%;
    display: flex;
    align-items: center;
    color: $secondary-blue;
    padding: 20px 18px;
    border: none;
    outline: none;

    @include media-breakpoint-down(md) {
      height: 45px;
      border-radius: 9px;
      font-size: 14 * $px-base;
      color: #1F1F1F;
      padding: 14px 13px;
      opacity: 1;
    }
  }

  .btnSearch {
    position: absolute;
    top: 5px;
    right: 5px;
    min-width: 120px;
    height: 50px;
    background: #33B0D9;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16 * $px-base;
    line-height: 117%;
    color: $white;
    border: none;

    @include media-breakpoint-down(md) {
      min-width: 86px;
      height: 34px;
    }
  }
}
